<template>
    <div>
        <div class="r-option-place r-center-flex r-isWrap">
            <InputCustom
                :rightIcon="true"
                :withError="false"
                v-model="request.search" 
                @keyup="setTime()" 
                @keydown="clearTime()" 
                @keydown.tab="setTime()" 
                @keyup.tab="clearTime()" 
                @keypress.enter="getData()" 
                placeholder="Search . . ."
                size='md'
                >
                <template #rightIcon>
                    <div class="y-inputIcon r-center-flex">
                        <img :src="require('@/assets/icons/others/search.svg')" alt="search">
                    </div>
                </template>
            </InputCustom>
        </div>

        <TableCustom 
            :columns="columns" 
            :request="request" 
            :fetchData="fetchData"
            :data="data"
            @setSortBy="setSortBy"
            @setShow="setShow" 
            @getData="getData"
            @mouseleave="popup = null"
            @togglePopup="togglePopup"
        >
            <template #index="props">
                {{curNumber(props.index)}}
            </template>

            <template #created_at="props">
                {{convertDateTime(props.row.created_at)}}
            </template>

            <template #username="props">
                {{props.row.username}}
            </template>

            <template #id_transaksi="props">
                {{props.row.id_transaksi}}
            </template>

            <template #rekening="props">
                {{props.row.bank_name}} - {{props.row.no_rek}} - {{props.row.nama_rek}}
            </template>

            <template #to_rek="props">
                {{props.row.bank_tujuan}} - {{props.row.no_rek_tujuan}} - {{props.row.rek_tujuan}}
            </template>

            <template #jumlah="props">
                {{price(props.row.jumlah)}}
            </template>

            <template #action="props">
                <div class="r-menu">
                    <img class="r-is-clickable" :src="require('@/assets/icons/others/more.svg')" @click.prevent="popup = curNumber(props.index)">
                    <div class="r-menu-items" v-if="popup == curNumber(props.index)" @mouseleave="popup = -1">
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="openApproveModal(props.row)">Approve</div>
                        <div class="r-menu-item y-red-text r-center-flex"  @click.prevent="openRejectModal(props.row)">Reject</div>
                    </div>
                </div>
            </template>
        </TableCustom>

        <ApproveModal 
            v-if="modal == 'approve'" 
            :inputType="inputType"
            @resetData="resetData"
            @getData="getData"
        ></ApproveModal>

        <RejectModal
            v-if="modal == 'reject'" 
            @resetData="resetData"
            @getData="getData"
        >
        </RejectModal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
import ApproveModal from '@/components/Transaction/ApproveModal'
import RejectModal from '@/components/Transaction/RejectModal'
import { price } from '@/common/currency'
export default {
    mixins: [
        common, 
        tableMixins
    ],
    components: {
        ApproveModal,
        RejectModal
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            data: 'transaction/getData',
            request: 'transaction/getRequest',
        })
    },
    data: () => ({
        columns: [
            {
                label: 'No',
                isSortable: false,
                fieldName: 'index',
                class: 'r-table-firstColumn'
            },
            {
                label: 'Tanggal Pembuatan',
                isSortable: true,
                fieldName: 'created_at',
                class: ''
            },
            {
                label: 'Username',
                isSortable: true,
                fieldName: 'username',
                class: ''
            },
            {
                label: 'No Depo',
                isSortable: false,
                fieldName: 'id_transaksi',
                class: ''
            },
            {
                label: 'Dari Rekening',
                isSortable: false,
                fieldName: 'rekening',
                class: ''
            },
            {
                label: 'Ke Rekening',
                isSortable: false,
                fieldName: 'to_rek',
                class: ''
            },
            {
                label: 'Jumlah',
                isSortable: false,
                fieldName: 'jumlah',
                class: ''
            },
            {
                label: 'Action',
                isSortable: false,
                fieldName: 'action',
                class: 'r-table-actionColumn'
            }
        ],
        modal: null,
        inputType: 'Tambah',
    }),
    methods: {
        ...mapActions ({
            resetItem: 'transaction/resetItem',
            resetRequest: 'transaction/resetRequest',
            setItem: 'transaction/setItem',
            getDataDepo: 'transaction/fetchDataDeposit',
        }),
        openApproveModal(item) {
            this.modal = 'approve'
            this.setItem(item)
        },
        openRejectModal(item) {
            this.modal = 'reject'
            this.setItem(item)
        },
        resetData() {
            this.modal = null
            this.resetItem()
            this.resetRequest()
        },
        price(value){
            return price(value)
        },
        async getData(type = 0) {
            this.fetchData = true
            if (type === 0) {
                this.request.page = 1
            } else {
                this.request.page += type
            }
            await this.getDataDepo()
            this.fetchData = false
            const response = this.getResponse('transaction')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            }
        },
        async initData() {
            const breadcrumb = [{
                name: 'Deposit',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Transaksi')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
        },
    }
}
</script>